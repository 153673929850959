<template>
    <section>
        <div class="page-header">
            <h3 class="page-title">
                Actualités
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">Actualités</li>
                </ol>
            </nav>
        </div>
        <div class="card-columns">
            <div class="card" v-for="post in posts" :key="post.id">
                <!-- image -->
                <img v-if="post.image" class="card-img-top" :src="post.image.content.originalImage" alt="Card image cap">
                <img v-else class="card-img-top" src="@/assets/images/samples/300x300/3.jpg" alt="Card image cap">

                <div class="card-body">
                <h4 class="card-title pointer" @click="gotoPostDetails(post.ulid)">{{post.title}}</h4>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad blanditiis quam, sequi dolorum excepturi repudiandae atque dignissimos voluptatum aperiam!</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:"posts",
    data(){
        return{ 
            posts:[]
        }
    },
    methods:{
        getPosts(){
            // axios.get('public/posts')
            axios.get('posts',{
                headers:{
                    "X-AUTH-TOKEN": localStorage.getItem('token')
                }
            })
            .then(resGetPosts=>{
                console.log({resGetPosts});
                this.posts = resGetPosts.data.result
            })
            .catch(errGetPosts => console.log(errGetPosts))
        },
        gotoPostDetails(ulid){
            this.$router.push({
                name:"postDetails",
                params:{
                    ulid:ulid
                }
            })
        }
    },
    mounted(){
        this.getPosts()
    }
}
</script>   